<template>
    <div>
        <AboutNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Same Day Build Guarantee</h1>

            <p>We offer a Same Day build guarantee on any unitary adapter or it's free!</p>

            <p>The unitary curb adapter ordered does not need to be a unitary curb that we have in stock or one that we've previously built. Just select "Today" for a ship day when placing your order.</p>

            <p>You have until 1pm Central to verify your drawing and submit a PO and it will be built and ship out of our facility that same day.  Please remember to include transit time to your delivery location - transit time is not guaranteed.</p>

            <p>There is a chance that we may be able to get your curb built if we receive your info after the cut-off, but submit by the cut-off time, select "Today" for ship day, and you have a money back guarantee!</p>
        </main>
    </div>
</template>

<script>
import AboutNav from '@/components/content/AboutNav.vue'
export default {
    name: "Guarantee",
    components: {
        AboutNav,
    },
    created() {
        this.$appStore.setTitle(['CDI\'s Guarantee']);
    },
}
</script>

<style scoped>

</style>