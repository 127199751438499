<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <ul class="nav nav-pills nav-stacked affix">
                <li class="cdi_tab"><router-link to="/aboutval">Core Values</router-link></li>
                <li class="cdi_tab"><router-link to="/engineering">Engineering</router-link></li>
                <li class="cdi_tab"><router-link to="/shipping">Shipping</router-link></li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "AboutNav",
}
</script>

<style scoped>

</style>